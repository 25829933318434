
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getChatHistory, getChatRecordList, exportChatList, getRiskList, getChatTotalinfo } from "@/api/request/user";

//组件
@Component({
  name: "ChatHistory",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private total: number = 0;
  private chatList: any[] = [];
  private riskList: any[] = [];
  private listLoading: boolean = false;

  //筛选数据
  private listParams: any = {
    //页面数据
    row: 8,
    page: 1,

    //临时数据
    time: [],

    //时间数据
    end_time: "",
    start_time: "",

    //筛选数据
    type: 1,
    chat_max: 0,
    chat_min: 0,
    excel: false,
    id: undefined,
    risk_label: "",
    userid: undefined,
    bloggerid: undefined,
  };

  //统计数据
  private totalData: any = {
    total: 0,
    total_user: 0,
    total_blogger: 0,
  };

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取警报类型
    this.getRiskList();

    //获取聊天列表
    this.getChatList();
  }

  //初始化时间
  private initTime(): void {
    //时间赋值
    this.listParams.end_time = `${DTCls.getCurDateTimeYMD()} 23:59:59`;
    this.listParams.start_time = `${DTCls.getCurDataTimeYMD1()} 00:00:00`;
    this.listParams.time = [DTCls.getCurDataTimeYMD1(), DTCls.getCurDateTimeYMD()];
  }

  //清空列表
  private resetList(): void {
    //清空列表
    this.chatList = [];
    this.messageList = [];

    //消息标题赋值
    this.messageTitle = "请选择聊天";
  }

  //处理重置
  private handleReset(): void {
    //清空数据
    this.listParams.page = 1;
    this.listParams.chat_max = 0;
    this.listParams.chat_min = 0;
    this.listParams.id = undefined;
    this.listParams.risk_label = "";
    this.totalData = { total: 0, total_user: 0, total_blogger: 0 };

    //初始化时间
    this.initTime();

    //清空列表
    this.resetList();

    //获取聊天列表
    this.getChatList();
  }

  //处理Excel
  private async handleExcel() {
    //显示加载
    this.listLoading = true;

    //数据赋值
    this.listParams.excel = true;
    if (this.listParams.id != undefined) this.listParams.id = GFunc.checkint(this.listParams.id);
    this.listParams.chat_min = GFunc.checkint(this.listParams.chat_min);
    this.listParams.chat_max = GFunc.checkint(this.listParams.chat_max);
    if (this.listParams.id == 0) this.listParams.id = undefined;

    //获取聊天数据
    const { data } = await exportChatList(this.listParams);

    //保存数据
    saveAs(data, `${this.listParams.time[0]}--${this.listParams.time[1]}聊天列表`);

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取聊天列表
  private async getChatList() {
    //清空列表
    this.resetList();

    //显示加载
    this.listLoading = true;

    //时间数据赋值
    if (this.listParams.time && this.listParams.time.length == 2) {
      this.listParams.end_time = `${this.listParams.time[1]} 23:59:59`;
      this.listParams.start_time = `${this.listParams.time[0]} 00:00:00`;
    } else {
      this.listParams.end_time = "";
      this.listParams.start_time = "";
    }
    this.listParams.total_user_type = 0;

    //数据赋值
    this.listParams.excel = false;
    if (this.listParams.id != undefined) this.listParams.id = GFunc.checkint(this.listParams.id);
    if (this.listParams.bloggerid == 0) this.listParams.bloggerid = undefined;
    if (this.listParams.userid == 0) this.listParams.userid = undefined;
    this.listParams.chat_min = GFunc.checkint(this.listParams.chat_min);
    this.listParams.chat_max = GFunc.checkint(this.listParams.chat_max);
    if (this.listParams.id == 0) this.listParams.id = undefined;

    //获取聊天数据
    const { data } = await getChatRecordList(this.listParams);

    //数据赋值
    this.total = data.total;
    this.chatList = data.list;
    this.totalData = data.total_im;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理筛选
  private handleFilter(): void {
    //第一页
    this.listParams.page = 1;

    //获取聊天列表
    this.getChatList();
  }

  //获取警报类型
  private async getRiskList() {
    //显示加载
    this.listLoading = true;

    //获取警报数据
    const { data } = await getRiskList({});

    //数据赋值
    this.riskList = data;
    this.riskList.unshift({ label: "", label_name: "请选择用户属性" });
  }

  //处理最大改变
  private handleChangeMax(): void {
    //最小数据赋值
    if (this.listParams.chat_max != 0 && this.listParams.chat_min > this.listParams.chat_max) {
      this.listParams.chat_min = this.listParams.chat_max;
    }
  }

  //处理翻页
  private handlePageChange(): void {
    //清空列表
    this.resetList();

    //获取聊天列表
    this.getChatList();
  }

  //获取聊天列表
  private async getChatListDetail() {
    //显示等待
    this.detailLoading = true;

    //获取聊天数据
    const { data } = await getChatTotalinfo(this.detailParams);

    //数据赋值
    this.detailList = data.list;
    this.detailTotal = data.total;

    //隐藏加载
    setTimeout(() => {
      this.detailLoading = false;
    }, 0.5 * 1000);
  }

  //获取通话记录
  private getCallRecord(data: any): string {
    //显示内容
    var detial: string = "";

    //语音通话
    if (data.media_type == 1) {
      detial = "📞";
    }
    //语音通话
    else {
      detial = "🎦";
    }

    //数据赋值
    if (data.reason == 1) {
      detial += "取消通话";
    } else if (data.reason == 2) {
      detial += "被拒绝通话";
    } else if (data.reason == 3) {
      //时间赋值
      detial += "语音通话：";
      detial += (Math.floor(data.duration / 60) < 10 ? "0" + Math.floor(data.duration / 60) : Math.floor(data.duration / 60).toString()) + ":";
      detial += Math.floor(data.duration % 60) < 10 ? "0" + Math.floor(data.duration % 60) : Math.floor(data.duration % 60).toString();
    } else if (data.reason == 4) {
      detial += "超时未接通";
    }

    //返回内容
    return detial;
  }

  //处理显示列表详情
  private handleChatListDetail(type: number): void {
    //显示界面
    this.detailType = type;
    this.detailDialog = true;
    this.scoreTitle = type == 1 ? "充值" : "积分";
    this.detailTitle = type == 1 ? "用户详情" : "博主详情";

    //页面数据
    this.detailParams.page = 1;
    this.detailParams.time = this.listParams.time;
    this.detailParams.end_time = this.listParams.end_time;
    this.detailParams.start_time = this.listParams.start_time;

    //筛选数据
    this.detailParams.sort_filter = [];
    this.detailParams.total_user_type = type;
    this.detailParams.id = this.listParams.id;
    this.detailParams.type = this.listParams.type;
    this.detailParams.userid = this.listParams.userid;
    this.detailParams.chat_max = this.listParams.chat_max;
    this.detailParams.chat_min = this.listParams.chat_min;
    this.detailParams.bloggerid = this.listParams.bloggerid;
    this.detailParams.risk_label = this.listParams.risk_label;

    //获取数据
    this.getChatListDetail();
  }

  //--------------------------------------- 聊天内容 ---------------------------------------
  //定义变量
  private activeChat: any = {}; //激活聊天
  private messageList: any[] = []; //消息列表
  private messageTotal: number = 0; //消息总条数
  private messageLoading: boolean = false; //显示加载
  private messageTitle: string = "请选择聊天"; //消息标题

  //消息参数
  private messageParams: any = {
    //页面数据
    page: 1,
    row: 10,

    //时间数据
    time: "",
    end_time: "",
    start_time: "",

    //用户数据
    user_rongyun_id: undefined,
    blogger_rongyun_id: undefined,
  };

  //添加消息
  private addMessage(): void {
    //数据赋值
    this.messageParams.row += 10;

    //获取消息
    this.getMessage(this.activeChat.blogger_info.rongyun_id, this.activeChat.user_info.rongyun_id);
  }

  //显示选择时间
  private chooseChatDate(): void {
    this.dateDialog = true;
  }

  //获取消息
  private async getMessage(blogger_id: string, user_id: string) {
    //显示加载
    this.messageLoading = true;

    //数据赋值
    this.messageParams.user_rongyun_id = user_id;
    this.messageParams.blogger_rongyun_id = blogger_id;

    //获取数据
    const { data } = await getChatHistory(this.messageParams);

    //列表赋值
    this.messageList = data.list;
    this.messageTotal = data.total;

    //隐藏加载
    this.messageLoading = false;
  }

  //--------------------------------------- 列表详情 ---------------------------------------
  //定义变量
  private detailList: any[] = [];
  private detailType: number = 0;
  private detailTotal: number = 0;
  private scoreTitle: string = "";
  private detailTitle: string = "";
  private detailDialog: boolean = false;
  private detailLoading: boolean = false;

  //筛选数据
  private detailParams: any = {
    //页面数据
    row: 8,
    page: 1,

    //时间数据
    time: [],
    end_time: "",
    start_time: "",

    //筛选数据
    type: 1,
    chat_max: 0,
    chat_min: 0,
    id: undefined,
    risk_label: "",
    sort_filter: [],
    total_user_type: 0,
    userid: undefined,
    bloggerid: undefined,
  };

  //关闭详情
  private detailCancel(): void {
    //隐藏界面
    this.detailDialog = false;

    //清空数据
    this.detailList = [];
  }

  //聊天点击
  private chatClick(chat: any): void {
    //数据赋值
    this.activeChat = chat;
    this.messageParams.page = 1;
    this.messageParams.row = 10;
    this.messageParams.time = "";
    this.messageParams.end_time = "";
    this.messageParams.start_time = "";
    this.totalData = { total: 0, total_user: 0, total_blogger: 0 };
    this.messageTitle = `${chat.user_info.nickname}  与  ${chat.blogger_info.nickname}的聊天记录`;

    //数据赋值
    this.getMessage(chat.blogger_info.rongyun_id, chat.user_info.rongyun_id);

    //滚动到最上面
    this.scrollTop();
  }

  //--------------------------------------- 时段选择 ---------------------------------------
  //定义变量
  private dateDialog: boolean = false; //对话框

  //滚动到最上
  private scrollTop(): void {
    //获取组件
    const messageC: any = document.querySelector(".chat-messages");

    //滚动
    messageC.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  //时间选择
  private dateConfirm(): void {
    //选择
    if (this.messageParams.time && this.messageParams.time.length) {
      //数据赋值
      this.messageParams.row = 10;
      this.messageParams.page = 1;
      this.messageParams.end_time = `${this.messageParams.time[1]} 23:59:59`;
      this.messageParams.start_time = `${this.messageParams.time[0]} 00:00:00`;

      //显示界面
      this.getMessage(this.activeChat.blogger_info.rongyun_id, this.activeChat.user_info.rongyun_id);

      //隐藏对话框
      this.dateDialog = false;

      //滚动到最上面
      this.scrollTop();
    }
    //选择
    else {
      this.$message.error("您还未选择时间呢");
    }
  }

  //隐藏对话框
  private dateCancel(): void {
    this.dateDialog = false;
  }
}
